import React from 'react';
import ImpulseResponseRecorder from './components/ImpulseResponseRecorder';

function App() {
  return (
    <div className="demo-container">
      <ImpulseResponseRecorder />
    </div>
  );
}

export default App;
